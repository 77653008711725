<template>
 <div class="container-fluid" >
   <b-modal
       v-model="modalShow"
       no-close-on-esc
       no-close-on-backdrop
       hide-header-close
       :hide-footer="currentPanel.runningStatus === 1"
       hide-header
   >
     <p class="my-4">系統正在處理中</p>
   </b-modal>
   <div class="row">
   <div class=" text-center col-sm-12 col-md-3 col-lg-3 col" >
     <b-pagination
         v-model="leftList.currentPage"
         :total-rows="leftList.history.length"
         :per-page="10"
         align="fill"
         size="sm"
         class="my-0"
     />
     <b-table
        :items="leftList.history"
        :fields="leftList.fields"
        responsive="sm"
        :per-page="10"
        :current-page="leftList.currentPage"
     >
       <template v-slot:cell(show_details)="row">
         <b-button size="sm" @click="row.toggleDetails" class="mr-2">
           {{ row.detailsShowing ? '縮起來' : '看看詳細資料'}}
         </b-button>
         <b-button :disabled="row.item.serial === currentPanel.serial" size="sm" class="mr-2" @click="currentPanel.serial = row.item['serial']">
           選擇
         </b-button>
       </template>
       <template #row-details="row">
         <p class="mb-1">
           全部名單（已審核/待行政審核/皆未審核/未填寫）： <br>
           {{row.item['done']}} /{{row.item['verified']}}/ {{row.item['filled']}} / {{row.item['unfilled']}}
         </p>
       </template>
     </b-table>
   </div>
     <div class="col" v-if="rightResults.result.originData.length >0">
     <div>
       <b-form-group
           id="fieldset-1"
           label="在此住宿名單中搜尋（不需查詢的因素留白即可）"
           label-for="input-1"
       >
         <b-input-group class="mb-2">
           <b-input-group-prepend is-text>
            學號：
           </b-input-group-prepend>
           <b-form-input
               type="text"
               max="16"
               v-model="rightResults.search.keyword"
               placeholder="範例：10811217"
               @keypress.enter="filter"
           />
           <b-input-group-prepend is-text>
            篩選：
           </b-input-group-prepend>
           <b-input-group-prepend>
             <b-form-select
                 v-model="rightResults.search.filterSelect"
                 :options="['全部狀態', '審核完畢', '等待行政審核', '等待樓長審核', '未填寫']"
             />
           </b-input-group-prepend>
           <b-input-group-prepend is-text>
             宿舍：
           </b-input-group-prepend>
           <b-input-group-prepend>
             <b-form-select
                 :options="rightResults.search.dormOption"
                 v-model="rightResults.search.dormCurrent"
             />
           </b-input-group-prepend>
           <b-form-input
               type="text"
               max="16"
               v-model="rightResults.search.room"
               placeholder="範例：A234"
               @keypress.enter="filter"
           />
           <b-input-group-append>
             <b-button v-on:click="filter" variant="primary">搜尋</b-button>
             <b-button v-on:click="syncSerialContext(currentPanel.serial)"><b-icon-arrow-repeat/></b-button>
           </b-input-group-append>
         </b-input-group>
       </b-form-group>
     </div>
     <div>
        <b-container fluid>
          <b-row>
            <b-col sm="5" md="6" class="my-1">
              <b-form-group
                  label="每一頁"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
              >
                <b-form-select
                    v-model="rightResults.result.perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="[5, 10, 15, 20, 30, 50, 75, 100]"
                />
              </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
              <b-pagination
                  v-model="rightResults.result.currentPage"
                  :total-rows="rightResults.result.lists.length"
                  :per-page="rightResults.result.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
              />
            </b-col>
          </b-row>
            <b-table
                striped hover
                responsive="sm"
                :items="rightResults.result.lists"
                :fields="rightResults.result.fields"
                :current-page="rightResults.result.currentPage"
                :per-page="rightResults.result.perPage"
            >
              <template #cell(form_id)="row">
                <b-checkbox
                  v-model="form_id"
                  :value="row.value"
                  :disabled="row.item.status === '審核完畢'"
                  v-if="row.item.status !== null"
                >
                  {{row.value}}
                </b-checkbox>
              </template>
              <template v-slot:cell(device)="row">
                <h4>
                  <b-badge
                    v-if="row.item.status !== null"
                    :variant="row.item.device.length == 0 ? 'secondary' : 'danger' "
                >{{ row.item.device.length == 0 ? "房間沒問題" : "房間有問題" }}
                  </b-badge>
                </h4>
              </template>

              <template #cell(status)="row" >
                {{row.item.status === null ? '未填寫' : row.item.status }}
              </template>
              <template #cell(actions)="row">
                <b-button size="sm" v-if="row.item.status === '等待行政審核' " @click="accept(row.item.form_id)" variant="primary" class="mr-1">
                  審核
                </b-button>
                <!--<b-button size="sm" v-if="row.item.status !== null " @click="delete(row.item.form_id)" variant="danger" class="mr-1">
                  刪除表單
                </b-button>-->
                <b-button
                    size="sm"
                    @click="row.toggleDetails"
                    v-if="row.item.status !== '未填寫'"
                    row-clicked="showVerifiedDetail"
                >
                  顯示詳細信息
                </b-button>
              </template>
              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="2"  v-if=" row.item.device !== null  ">
                      損壞:
                      <b v-for="data in row.item.device" :key="data">
                        <b> {{copyTochengen(data)}} </b>
                      </b>
                      <br>
                      其他:
                      <b>{{row.item.other}}</b>
                    </b-col>
                    <b-col sm="2"  v-if="row.item.floorMaster_is_verified !== false">
                      審核樓長：{{ row.item.floorMaster_name }}
                    </b-col>
                    <b-col sm="2"  v-if="row.item.floorMaster_is_verified !== false">
                      樓長審核時間：{{ row.item['floorMaster_verify_time'] }}
                    </b-col>
                    <b-col sm="2"  v-if="row.item.admin_is_verified !== false">
                      審核行政人員：{{ row.item.admin_name }}
                    </b-col>
                    <b-col sm="2"  v-if="row.item.admin_is_verified !== false">
                      行政人員審核時間：{{ row.item['admin_verify_time']  }}
                    </b-col>
                    <b-col sm="2" >
                      表單創建時間：{{ row.item['create_time']  }}
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
        </b-container>
     </div>
     <hr>
     <b-input-group prepend="執行操作：" class="mt-3">
       <b-input-group-append>
         <b-button @click="selectToVerify(form_id)" >選中的審核</b-button>
         <b-button @click="forAllGoodToVerify" >樓長審核的全部審核</b-button>
       </b-input-group-append>
     </b-input-group>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
export default {
  name: "checkFormManagement",

  mounted: function(){
    axios.get('/api/checkin_form/get_by_serial')
        .then((response) => {
          this.leftList.history = response.data;
        })
        .catch((error) => {
          window.console.log(error);
        });
  },
  data: function () {
    return {
      currentPanel:{
        serial: "",
        runningStatus: 0,
        runningMsg: ""
      },
      leftList:{
        history: [],
        fields: [
          {
            key: "serial",
            label: "清單"
          },
          {
            key: "show_details",
            label: "操作"
          }
        ],
        currentPage: 1
      },
      rightResults: {
        search: {
          stu: ["學號"],
          dormOption:[
              "全部",
              "一宿",
              "二宿",
              "忠孝樓"
          ],
          current: "學號",
          keyWord: "",
          dormCurrent: "全部",
          room: "",
          filterSelect: "全部狀態"
        },
        result: {
          currentPage: 1,
          perPage: 10,
          lists: [],
          originData: [],
          fields: [
            {key: "form_id", label: '表單編號'},
            {key: "stu_id", label: "學號"},
            {key: "name", label: "姓名"},
            {key: "room_name4human", label: "床位", sortable: true},
            {key: "device" ,label: "是否故障"},
            {key: "status", label: "狀態", sortable: true},
            {key: "actions", label: "操作"}
          ]
        },
        listAction:{
          current: "",
          actions:[
            {value: "", text: ""},
            {value: "1", text: "選中的審核"},
            {value: "2", text: "樓長已審核的全部審核"}
          ]
        },
      },
      form_id: []
    }
  },
  watch:{
    'currentPanel.serial'(newValue){
      this.syncSerialContext(newValue)
    }
  },
  computed: {
    modalShow: function () {
      return this.currentPanel.runningStatus !== 0
    }
  },
  methods: {
    syncSerialContext(serial){
      this.currentPanel.runningStatus = 1;
      this.rightResults.result.originData = [];
      this.rightResults.search.current = "學號";
      this.rightResults.search.keyword= "";
      this.rightResults.search.dormCurrent = "全部";
      this.rightResults.search.room = "";
      this.rightResults.search.filterSelect = '全部狀態';
      this.rightResults.result.currentPage = 1;
      this.rightResults.result.lists = [];
      this.rightResults.result.originData = [];
      this.form_id = [];
      axios.get(
          '/api/checkin_form/admin',
          {
            params: {
              serial: serial,
            }
          }
      ).then((response)=>{
        let temp;
        let output = [];
        for(let item of response.data){
          temp = Object.assign(item);
          if(temp["floorMaster_verify_time"] !== null)
            temp["floorMaster_verify_time"] = new Date(temp["floorMaster_verify_time"]).toLocaleString()
          if(temp["admin_verify_time"] !== null)
            temp["admin_verify_time"] = new Date(temp["admin_verify_time"]).toLocaleString()
          if(temp["create_time"] !== null)
            temp["create_time"] = new Date(temp["create_time"]).toLocaleString()
          output.push(temp)
        }
        this.rightResults.result.originData = output;
        this.rightResults.result.lists = this.rightResults.result.originData;
        this.currentPanel.runningStatus = 0;
      })
    },
    filter(){
      let temp1 = [];
      let temp2 = [];
      temp1 = this.rightResults.result.originData;
      if(this.rightResults.search.keyword !== ""){
        for(let item of temp1){
          if(this.rightResults.search.current === "學號" && item['stu_id'] === this.rightResults.search.keyword){
            temp2.push(item)
          }
        }
        temp1 = temp2;
      }
      temp2 = [];
      for(let item of temp1){
        if(this.rightResults.search.dormCurrent === '全部'){
          temp2.push(item)
        }else if(this.rightResults.search.dormCurrent === item['dorm']){
          temp2.push(item)
        }
      }
      temp1 = temp2;
      temp2 = [];
      for(let item of temp1){
        if(this.rightResults.search.room === ""){
          temp2.push(item)
        }else if(item['room'] === this.rightResults.search.room){
          temp2.push(item)
        }
      }
      temp1 = temp2; // 目前被篩到剩下的
      temp2 = []; // 即將顯示的
      for(let item of temp1){
        switch (this.rightResults.search.filterSelect){
          case "全部狀態":
            temp2.push(item);
            break;
          case "審核完畢":
            if(item['status'] === '審核完畢'){
              temp2.push(item);
            }
            break;
          case "等待樓長審核":
            if(item['status'] === '等待樓長審核'){
              temp2.push(item);
            }
            break;
          case '等待行政審核':
            if(item['status'] === '等待行政審核'){
              temp2.push(item);
            }
            break;
          case '未填寫':
            if(item['status'] === null){
              temp2.push(item);
            }
        }
      }
      this.rightResults.result.lists = temp2;
    },
    accept(form_id){
      axios.post('/api/checkin_form/admin',{
        form_id: [form_id],
      }).then(()=>{
        window.alert("審核成功");
        this.syncSerialContext(this.currentPanel.serial);
      }).catch((error)=> {
        //錯誤代碼
        if (error.response.status === 400 || error.response.data.hasOwnProperty('msg')) {
          window.alert(error.response.data.msg);
          this.upload.sendBtnMsg = '重新審核';
        } else {
          window.alert('不明的錯誤');
          this.upload.sendBtnMsg = '重新審核';
        }
      })
    },
    selectToVerify(form_id) {
      axios.post('/api/checkin_form/admin', {
        form_id: form_id
      }).then(()=>{
        this.syncSerialContext(this.currentPanel.serial);
        window.alert("審核成功 請稍等一下");
      }).catch((error)=>{
        //錯誤代碼
        if (error.response.status === 400 || error.response.data.hasOwnProperty('msg')) {
          window.alert(error.response.data.msg);
          this.syncSerialContext(this.currentPanel.serial);
          this.upload.sendBtnMsg = '重新審核';
        } else {
          window.alert('不明的錯誤');
          this.upload.sendBtnMsg = '重新審核';
          this.syncSerialContext(this.currentPanel.serial);
        }
      })
    },
    forAllGoodToVerify() {
      let form_id = this.form_id;
      for(let data of this.rightResults.result.originData){
        if(data.status === '等待行政審核'){
          form_id.push(data.form_id);
        }
      }
      axios.post('/api/checkin_form/admin' ,{
        form_id: form_id
      }).then(()=>{
      this.syncSerialContext(this.currentPanel.serial);
      window.alert("審核成功 請稍等一下");
    }).catch((error)=>{
      //錯誤代碼
      if (error.response.status === 400 || error.response.data.hasOwnProperty('msg')) {
        window.alert(error.response.data.msg);
        this.upload.sendBtnMsg = '重新審核';

        this.syncSerialContext(this.currentPanel.serial);
      } else {
        window.alert('不明的錯誤');
        this.upload.sendBtnMsg = '重新審核';
        this.syncSerialContext(this.currentPanel.serial);
      }
    })
    },
    delete(form_id){
      axios.post('/api/checkin_form/admin',{
        form_id: [form_id],
      }).then(()=>{
        window.alert("刪除成功");
        this.syncSerialContext(this.currentPanel.serial);
      }).catch((error)=> {
        //錯誤代碼
        if (error.response.status === 400 || error.response.data.hasOwnProperty('msg')) {
          window.alert(error.response.data.msg);
          this.upload.sendBtnMsg = '重新審核';
        } else {
          window.alert('不明的錯誤');
          this.upload.sendBtnMsg = '重新審核';
        }
      })
    },
    copyTochengen(x){
      let dict = {
        "null": '我的房間沒問題',
        "bed_board" : '床板',
        "table":  '書桌',
        "wardrobe": "衣櫃",
        "bathroom": '浴室',
        "shower_head":  '蓮蓬頭',
        "washbasin": '洗手台',
        "mirror": '鏡子',
        "toilet":  '馬桶',
        "ac":  '冷氣',
        "ac_filter": '冷氣濾網',
        "ac_controller": '冷氣遙控器',
        "wall": '牆壁',
        "glass":  '陽台圍牆上玻璃片傾斜'
      }
      return dict[x];
    }

  },

}
</script>

<style scoped>

</style>
